/* common RD UI utilities
 * dependencies: lodash
 */

var RDJS = (function (window, document) {

  'use strict';


  /* =helper functions
   * =================
   */

  // quick no-effort polyfill for Element.matches
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
  }


  // cast to array, because _.toArray and _.castArray are inadequate when
  // the argument could be either a node or nodeList. Also doubles as
  // an array copier
  function toArray(collection) {
    var result = [];
    if (collection) {
      if (typeof collection.length === 'number') {
        for (var i = 0; i < collection.length; i++) {
          result.push(collection[i]);
        }
      } else {
        result.push(collection);
      }
    }
    return result;
  }


  // get all elements not contained within certain parents
  // because css selectors are currently inadequate to solve this problem
  // in the general case
  function getElementsWithoutParent(selector, parentBlacklist, currentParent) {
    if (!currentParent) {
      currentParent = document.documentElement;
    }

    var result = [];

    if (!currentParent.children) {
      return result;
    }

    for (var i = 0; i < currentParent.children.length; i++) {
      if (currentParent.children[i].matches(selector)) {
        result.push(currentParent.children[i]);
      }
      if (!currentParent.children[i].matches(parentBlacklist)) {
        result = result.concat(getElementsWithoutParent(selector, parentBlacklist, currentParent.children[i]));
      }
    }
    return result;
  }


  // return a valid url-encoded string of parameters from an object
  // currently does not support nested objects
  function objectToParameters(obj) {
    var parts = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        parts.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
      }
    }
    return parts.join('&');
  }


  // produce a valid html id (with some additional considerations) from an
  // arbitrary string. Providing a value for 'defaultId' will cover cases where
  // the string is blank or consists entirely of characters we will strip out
  function generateHtmlId(str, defaultId) {
    // sanitize our id value a bit. The html5 spec is /very/ permissive about
    // what can be in an id (pretty much anything but spaces goes), but strip
    // out some additional characters in case we're using this as a url fragment
    var idString = str
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[\.\?\$\/ #&%@,]/g, '')
      .toLowerCase();

    idString = idString || defaultId || '';  // in case str was empty or all special chars

    // if we have an id conflict, increment until we don't. We could append
    // some kind of random value, but we want this to produce the same
    // linkable hash each run
    var tryCount = 0;
    var existingElement = document.getElementById(idString);
    while (existingElement) {
      tryCount++;
      existingElement = document.getElementById(idString + '-' + String(tryCount));
    }
    if (tryCount > 0) {
      idString += '-' + String(tryCount);
    }

    return idString;
  }


  // xhr helper
  function xhr(method, url, callback, data) {
    var _validMethods = ['GET', 'POST', 'HEAD', 'PUT', 'DELETE', 'CONNECT', 'OPTIONS', 'TRACE', 'PATCH'],
        xhr = new XMLHttpRequest();

    if (!method) {
      console.error('No method provided to xhr');
      return false;
    }
    method = method.toUpperCase();
    if (!_validMethods.includes(method)) {
      console.error(method + ' is not a valid http request method');
      return false;
    }

    if (!url) {
      console.error('No url provided to xhr');
      return false;
    }

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          if (callback && typeof callback === 'function') {
            callback(xhr.responseText);
          } else {
            console.log(xhr.responseText);
          }
        } else {
          console.error('XHR returned a status of ' + xhr.status);
          console.log(xhr);
        }
      }
    };

    xhr.open(method, url);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    if (method === 'POST' && data) {
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      if (typeof data === 'object') {
        xhr.send(objectToParameters(data));
      } else {
        xhr.send(data);
      }
    } else {
      xhr.send();
    }
  }


  // determine whether an element is hidden from view
  function isHidden(el) {
    const styles = window.getComputedStyle(el);

    return styles.display === 'none' ||
      styles.visibility === 'hidden' ||
      styles.opacity === 0 ||
      (el.clientHeight <= 1 && (styles.overflow === 'hidden' || styles.overflowY === 'hidden'));
  }


  /* =toggles
   * ========
   *
   * a one-to-many class toggler activated on click
   *
   * default elements:
   * <el data-toggle-target="[keyword|selector]" />
   *
   * external toggle interactions
   * <el data-toggle-exit="esc outside" />
   */

  var toggles = (function () {
    var toggleList = [],

        togglePrototype = {
          origin: null,
          targets: [],
          activeClass: 'is-active',
          deactivateOnEsc: false,
          deactivateOnOutside: false,
          useFragments: false,
          useFocus: false,

          // make toggle go
          activate: function activate(force) {
            _.forEach([this.origin].concat(this.targets), function (el, i) {
              if (typeof force === 'boolean') {
                el.classList.toggle(this.activeClass, force);
              } else {
                el.classList.toggle(this.activeClass);
              }
              // if this is the origin, set control state
              if (i === 0) {
                el.setAttribute('aria-expanded', (el.classList.contains(this.activeClass)).toString());
              // if this is a toggle, set aria hidden state
              } else {
                if (el === document.body) {
                  this.origin.setAttribute('aria-expanded', '');
                } else {
                  el.setAttribute('aria-hidden', (!el.classList.contains(this.activeClass)).toString());
                }
              }
            }.bind(this));

            // focus first target as a convenience to kb/sr users
            if (this.useFocus && this.origin.classList.contains(this.activeClass) && this.targets.length > 0) {
              let focusable = this.targets[0].querySelector('input:not([type="hidden"]), textarea, select, a, button');
              if (focusable) {
                focusable.focus();
              } else {
                this.targets[0].focus();
              }
            }

            // write url fragment, if applicable
            if (this.useFragments && this.origin.id) {
              if (this.origin.classList.contains(this.activeClass)) {
                window.history.replaceState(null, '', '#' + encodeURIComponent(this.origin.id));
              } else {
                window.history.replaceState(null, '', window.location.href.split('#')[0]);
              }
            }

            if (typeof this.onActivate === 'function') {
              this.onActivate(this);
            }

            return this;
          },

          // get targets, generate a11y attributes, and add event listeners
          init: function init() {
            this.targets = toArray(this.targets);

            // set up ids
            this.origin.id = this.origin.id || generateHtmlId(this.origin.textContent.substr(0, 24), 'toggle');
            _.forEach(this.targets, function generateTargetId(target) {
              target.id = target.id || generateHtmlId(this.origin.textContent.substr(0, 24) + '-pane', 'pane');
            }.bind(this));

            // set initial a11y attributes
            this.origin.setAttribute('role', 'button');
            this.origin.setAttribute('aria-expanded', (this.origin.classList.contains(this.activeClass)).toString());
            this.origin.setAttribute('aria-controls', this.targets[0].id);
            _.forEach(this.targets, function linkTargetToOrigin(target) {
              if (target === document.body) {
                this.origin.setAttribute('aria-controls', '');
                this.origin.setAttribute('aria-expanded', '');
              } else {
                target.setAttribute('aria-labelledby', this.origin.id);
              }
            }.bind(this));

            // target focusing
            if (this.origin.getAttribute('data-toggle-focus') === 'true') {
              this.useFocus = true;
            }

            // make toggle focusable if it isn't a focusable element by default
            this.origin.setAttribute('tabindex', '0');

            // allow programmatic focus on first target content element
            if (this.targets.length > 0) {
              this.targets[0].setAttribute('tabindex', '-1');
            }

            // bind listeners
            this.origin.addEventListener('click', function (ev) {
              ev.preventDefault();
              this.activate();
            }.bind(this));
            this.origin.addEventListener('keydown', function (ev) {
              // bind spacebar and return in case we're not using a native
              // button element that includes these interactions implicitly
              if (ev.keyCode === 13 || ev.keyCode === 32) {
                ev.preventDefault();
                this.activate();
              }
            }.bind(this));

            setTimeout(function () {
              _.forEach(this.targets, function (target) {
                target.setAttribute('aria-hidden', (isHidden(target)).toString());
              });
            }.bind(this), 0);

            if (typeof this.onInit === 'function') {
              this.onInit(this);
            }

            return this;
          },

          // optional callbacks
          onActivate: null,
          onInit: null
        };

    // private: parses a target string and returns a matching array
    function findTargets(el) {
      var targetString = el.getAttribute('data-toggle-target');
      if (!targetString) {
        return [];
      } else if (targetString === 'next') {
        return [el.nextElementSibling];
      } else if (targetString === 'parent') {
        return [el.parentNode];
      } else if (targetString === 'parentparent') {
        return [el.parentNode.parentNode];
      } else if (targetString === 'parentnext') {
        return [el.parentNode.nextElementSibling];
      } else if (targetString === 'parentparentnext') {
        return [el.parentNode.parentNode.nextElementSibling];
      }
      return toArray(document.querySelectorAll(targetString));
    }

    // public: find any toggles in the specified node and add them
    function addChildren(parent) {
      _.forEach(parent.querySelectorAll('[data-toggle-target]'), function addNewToggle(el) {
        var options = {
          targets: findTargets(el),
          deactivateOnEsc: /\besc\b/.test(el.getAttribute('data-toggle-exit')),
          deactivateOnOutside: /\boutside\b/.test(el.getAttribute('data-toggle-exit')),
          useFragments: Boolean(el.getAttribute('data-toggle-fragment'))
        };
        add(el, options);
      });
    }

    // public: make node or nodeList toggleable
    function add(els, options) {
      options = options || {};
      els = toArray(els);
      _.forEach(els, function (el) {
        var toggle = _.assign(Object.create(togglePrototype), { origin: el }, options);
        toggleList.push(toggle);
        toggle.init();
      });
      return list();
    }

    // public: returns an array of all toggles on the page
    function list() {
      return toggleList;
    }

    // public: returns all toggle objects whose origins match a given css selector
    function find(selector) {
      var result = [];
      _.forEach(list(), function (toggle) {
        if (toggle.origin.matches(selector)) {
          result.push(toggle);
        }
      });
      return result;
    }

    // public: returns a single toggle object whose origin matches a given css selector
    function get(selector) {
      var result = null;
      _.forEach(list(), function (toggle) {
        if (toggle.origin.matches(selector)) {
          result = toggle;
          return false;
        }
      });
      return result;
    }

    // public: initialize by adding default elements
    function init() {
      addChildren(document);

      // open any toggles that match url fragment
      if (window.location.hash) {
        var urlFragment = decodeURIComponent(window.location.hash.substr(1));
        _.forEach(list(), function matchGroupFragment(group) {
          _.forEach([group.origin].concat(group.targets), function matchElementFragment(el) {
            if (el.id === urlFragment) {
              group.activate(true);
            }
          });
        });
      }

      // add global close listeners
      document.documentElement.addEventListener('keyup', function (ev) {
        if (ev.keyCode === 27) {
          _.forEach(list(), function (toggle) {
            if (toggle.deactivateOnEsc) {
              toggle.activate(false);
            }
          });
        }
      });
      document.body.addEventListener('click', function (ev) {
        _.forEach(list().filter(function (toggle) { return toggle.deactivateOnOutside; }), function (toggle) {
          var deactivate = true,
              els = [toggle.origin].concat(toggle.targets);
          for (var i = els.length - 1; i >= 0; i--) {
            if (els[i] === ev.target || els[i].contains(ev.target)) {
              deactivate = false;
              break;
            }
          }
          if (deactivate) {
            toggle.activate(false);
          }
        });
      });
    }

    return {
      add: add,
      addChildren: addChildren,
      list: list,
      find: find,
      get: get,
      init: init
    };
  }());


  /* =tabs
   * =====
   *
   * a class switcher wherein only one element (or pair of elements)
   * may be active at a time
   *
   * default elements:
   * <el class="tabs [is-automated]?">
   *   <el class="tabs__tab" />
   *   <el class="tabs__pane" />
   * </el>
   */


  var tabs = (function () {
    var tabGroupList = [],

        tabGroupPrototype = {
          // preferences
          automated: false,
          slideDuration: 5000,
          useFragments: false,

          // calculated attributes
          active: 0,
          count: 0,

          // elements
          container: null,
          tabs: null,
          panes: null,
          nextTriggers: null,
          previousTriggers: null,
          playTriggers: null,
          pauseTriggers: null,

          // default (overrideable) selectors used in init()
          containerSelector: '.tabs',
          tabSelector: '.tabs__tab',
          paneSelector: '.tabs__pane',
          nextSelector: '.tabs__next',
          previousSelector: '.tabs__previous',
          playSelector: '.tabs__play',
          pauseSelector: '.tabs__pause',

          // classes
          activeClass: 'is-active',
          lastActiveClass: 'is-last-active',
          nextClass: 'is-next',
          previousClass: 'is-previous',
          initClass: 'is-enhanced',
          playingClass: 'is-playing',

          // utility
          timer: null,

          changeTo: function changeTo(index, interruptTimer, isFirstLoad) {
            if (index === 'next') {
              index = this.getNextIndex();
            } else if (index === 'previous') {
              index = this.getPreviousIndex();
            } else if (typeof index !== 'number' || index < 0 || index >= this.count) {
              console.warn('given index not recognized');
              return this;
            }

            if (interruptTimer && this.automated) {
              this.pause();
            }

            // reset state classes to blank slate
            _.forEach(this.tabs.concat(this.panes), function (el) {
              el.classList.remove(this.activeClass);
              el.classList.remove(this.lastActiveClass);
              el.classList.remove(this.nextClass);
              el.classList.remove(this.previousClass);
            }.bind(this));

            // reset aria attributes to defaults
            _.forEach(this.tabs, function (tab) {
              tab.setAttribute('aria-selected', 'false');
            }.bind(this));
            _.forEach(this.panes, function (pane) {
              pane.setAttribute('aria-hidden', 'true');
            }.bind(this));

            // mark the tab we're changing /from/ for the purposes of animation
            if (this.active !== index) {
              if (this.tabs[this.active]) {
                this.tabs[this.active].classList.add(this.lastActiveClass);
              }
              if (this.panes[this.active]) {
                this.panes[this.active].classList.add(this.lastActiveClass);
              }
            }

            // make it so
            this.active = index;

            // add all state classes and aria states
            if (this.tabs[index]) {
              this.tabs[index].classList.add(this.activeClass);
              this.tabs[index].setAttribute('aria-selected', 'true');
            }
            if (this.panes[index]) {
              this.panes[index].classList.add(this.activeClass);
              this.panes[index].setAttribute('aria-hidden', 'false');
            }
            if (this.tabs[this.getNextIndex()]) {
              this.tabs[this.getNextIndex()].classList.add(this.nextClass);
            }
            if (this.panes[this.getNextIndex()]) {
              this.panes[this.getNextIndex()].classList.add(this.nextClass);
            }
            if (this.tabs[this.getPreviousIndex()]) {
              this.tabs[this.getPreviousIndex()].classList.add(this.previousClass);
            }
            if (this.panes[this.getPreviousIndex()]) {
              this.panes[this.getPreviousIndex()].classList.add(this.previousClass);
            }

            // handle fragment ids
            // no need to do this on initial page load, because either it's loading the default
            // (first) tab or we're loading from an existing fragment specifier
            if (this.useFragments && this.tabs[index] && this.tabs[index].id && !isFirstLoad) {
              window.history.replaceState(null, '', '#' + encodeURIComponent(this.tabs[index].id));
            }

            // focus first element inside selected pane for a better screen-reader experience
            if (!isFirstLoad && !this.automated && this.panes[index] && this.panes[index].children.length > 0) {
              this.panes[index].children[0].setAttribute('tabindex', '-1');
              this.panes[index].children[0].focus();
            }

            if (interruptTimer && this.automated) {
              this.play();
            }
            if (typeof this.onChange === 'function') {
              this.onChange(this);
            }
            return this;
          },

          play: function play() {
            this.container.classList.add(this.playingClass);
            if (this.timer > 0) {
              return false;
            }
            this.timer = setInterval(function () {
              this.changeTo('next');
            }.bind(this), this.slideDuration);
            if (typeof this.onPlay === 'function') {
              this.onPlay(this);
            }
            return this;
          },

          pause: function pause() {
            this.container.classList.remove(this.playingClass);
            clearInterval(this.timer);
            this.timer = 0;
            if (typeof this.onPause === 'function') {
              this.onPause(this);
            }
            return this;
          },

          getNextIndex: function getNextIndex() {
            return (this.active + 1) % this.count;
          },

          getPreviousIndex: function getPreviousIndex() {
            return this.active <= 0 ? this.count - 1 : this.active - 1;
          },

          generateTabIds: function generateTabIds() {
            _.forEach(this.tabs, function generateTabId(el) {
              if (el.id) return false;  // skip if an id has been provided for us
              el.id = generateHtmlId(el.textContent, 'tab');
            });
          },

          generateAriaAttributes: function generateAriaAttributes() {
            for (var i = 0; i < this.count; i++) {
              // make sure each pane also has an id
              // if an id is not present, generate from the associated tab id
              if (this.tabs[i] && this.panes[i]) {
                if (!this.panes[i].id) {
                  this.panes[i].id = this.tabs[i].id + '-pane';
                }

                // make sure each tab is linked to its associated pane
                if (this.tabs[i].tagName.toLowerCase() == 'a') {
                  this.tabs[i].href = '#' + this.panes[i].id;
                }
                this.tabs[i].setAttribute('aria-controls', this.panes[i].id);

                // make sure each pane is labeled by its associated tab
                this.panes[i].setAttribute('aria-labelledby', this.tabs[i].id);
              }
            }
          },

          init: function init() {
            // default elements
            this.tabs = toArray(this.tabs ||
              getElementsWithoutParent(this.tabSelector, this.containerSelector, this.container));
            this.panes = toArray(this.panes ||
              getElementsWithoutParent(this.paneSelector, this.containerSelector, this.container));
            this.nextTriggers = toArray(this.nextTriggers ||
              getElementsWithoutParent(this.nextSelector, this.containerSelector, this.container));
            this.previousTriggers = toArray(this.previousTriggers ||
              getElementsWithoutParent(this.previousSelector, this.containerSelector, this.container));
            this.playTriggers = toArray(this.playTriggers ||
              getElementsWithoutParent(this.playSelector, this.containerSelector, this.container));
            this.pauseTriggers = toArray(this.pauseTriggers ||
              getElementsWithoutParent(this.pauseSelector, this.containerSelector, this.container));

            // get final tabs count
            this.count = Math.max(this.tabs.length, this.panes.length);

            // generate tab ids for a11y and fragment links
            this.generateTabIds();

            // link tabs and panes in markup for screen reader benefit
            this.generateAriaAttributes();

            // apply count as container class to help css sizing
            this.container.classList.add('tabs--' + String(this.count));

            // attach event listeners
            _.forEach(this.tabs, function (tab, index) {
              tab.setAttribute('tabindex', '0');
              tab.addEventListener('click', function (ev) {
                ev.preventDefault();
                this.changeTo(index, true);
              }.bind(this));
              tab.addEventListener('keydown', function (ev) {
                if (ev.keyCode === 13 || ev.keyCode === 32) {
                  ev.preventDefault();
                  this.changeTo(index, true);
                }
              }.bind(this));
            }.bind(this));
            _.forEach(this.nextTriggers, function (el) {
              el.addEventListener('click', function (ev) {
                ev.preventDefault();
                this.changeTo('next', true);
              }.bind(this));
            }.bind(this));
            _.forEach(this.previousTriggers, function (el) {
              el.addEventListener('click', function (ev) {
                ev.preventDefault();
                this.changeTo('previous', true);
              }.bind(this));
            }.bind(this));
            _.forEach(this.pauseTriggers, function (el) {
              el.addEventListener('click', function (ev) {
                ev.preventDefault();
                this.pause();
              }.bind(this));
            }.bind(this));
            _.forEach(this.playTriggers, function (el) {
              el.addEventListener('click', function (ev) {
                ev.preventDefault();
                this.play();
              }.bind(this));
            }.bind(this));

            // automated slideshows get paused on mouseover
            if (this.automated) {
              this.container.addEventListener('mouseenter', this.pause.bind(this));
              this.container.addEventListener('mouseleave', this.play.bind(this));
            }

            // if we have a fragment specifier, use it; otherwise, the first tab is open by default
            if (this.useFragments && window.location.hash) {
              var fragment = decodeURIComponent(window.location.hash.substr(1));
              for (var i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i].id === fragment) {
                  this.active = i;
                  break;
                }
              }
            }

            this.changeTo(this.active, false, true);

            if (this.automated) {
              this.play();
            }

            if (typeof this.onInit === 'function') {
              this.onInit(this);
            }

            this.container.classList.add(this.initClass);

            return this;
          },

          // optional callbacks
          onChange: null,
          onPlay: null,
          onPause: null,
          onInit: null
        };

    // public: make node or nodeList into tab groups
    function add(els, options) {
      options = options || {};
      els = toArray(els);
      _.forEach(els, function (el) {
        var tabGroup = _.assign(Object.create(tabGroupPrototype), { container: el }, options);
        tabGroupList.push(tabGroup);
        tabGroup.init();
      });
      return list();
    }

    // public: returns an array of all tab groups on the page
    function list() {
      return tabGroupList;
    }

    // public: returns all tab groups whose containers match a given css selector
    function find(selector) {
      var result = [];
      _.forEach(list(), function (tabGroup) {
        if (tabGroup.container.matches(selector)) {
          result.push(tabGroup);
        }
      });
      return result;
    }

    // public: returns a single tab group whose container matches a given css selector
    function get(selector) {
      var result = null;
      _.forEach(list(), function (tabGroup) {
        if (tabGroup.container.matches(selector)) {
          result = tabGroup;
          return false;
        }
      });
      return result;
    }

    // public: initialize by adding default elements
    function init() {
      _.forEach(toArray(document.getElementsByClassName('tabs')), function addTabsContainer(container) {
        add(container, {
          automated: container.matches('.tabs--automated'),
          useFragments: container.matches('.tabs--use-fragments'),
        });
      });
    }

    return {
      add: add,
      list: list,
      find: find,
      get: get,
      init: init
    };
  }());


  /* =shared height elements
   * =======================
   *
   * match minimum heights across disparate elements
   *
   * usage:
   * <el data-height-group="groupname" />
   */

  var sharedHeights = (function () {
    var heightGroups = {};

    // public: update all shared-height elements
    function update() {
      var g, thisGroup, heights, maxHeight;

      function resetHeight(el) {
        el.style.minHeight = 0;
        heights.push(el.offsetHeight);
      }
      function applyHeight(el) {
        el.style.minHeight = maxHeight + 'px';
      }

      for (g in heightGroups) {
        if (heightGroups.hasOwnProperty(g)) {
          thisGroup = heightGroups[g];
          heights = [];
          _.forEach(thisGroup, resetHeight);
          maxHeight = Math.max.apply(null, heights);
          _.forEach(thisGroup, applyHeight);
        }
      }
    }

    // public: add shared-height functionality to a node or nodelist
    function add(els, groupName) {
      _.forEach(toArray(els), function (el) {
        var group = groupName || el.getAttribute('data-height-group');
        if (!group) {
          console.warn('No group specified for shared-height element');
          return false;
        }
        heightGroups[group] = heightGroups[group] || [];
        heightGroups[group].push(el);
      });
      return list();
    }

    // public: return heightGroups object
    function list() {
        return heightGroups;
    }

    // public: initialize with default elements
    function init() {
      add(document.querySelectorAll('[data-height-group]'));
      update();
    }

    var debouncedUpdate = _.debounce(update, 75);
    window.addEventListener('resize', debouncedUpdate);
    window.addEventListener('load', debouncedUpdate);

    return {
      add: add,
      list: list,
      update: update,
      init: init
    };
  }());


  /* =waypoints
   * ==========
   *
   * a scrolling waypoint-based class switcher, for when skrollr is overkill
   *
   * default elements:
   * <el data-waypoints="waypoint[ persist]?: class" />
   *     where waypoint is a number and a unit of measurement (%, px, em).
   *     multiple waypoints may be separated by commas
   */

  var waypoints = (function () {
    var waypointElementList = [],
        waypointElementPrototype = {
          el: null,
          waypoints: [],

          init: function () {
            var configString = this.el.getAttribute('data-waypoints');
            if (!configString) { return false; }

            _.forEach(configString.split(','), function (config) {
              var configParts = config.split(':'),
                  val, cl, unit, persist = false, waypoint;

              try {
                val = configParts[0].trim();
                cl = configParts[1].trim();
                if (val.substr(-7) === 'persist') {
                  persist = true;
                  val = val.slice(0, -7).trim();
                }
                unit = val.replace(/\d|,/g, '') || '%';
                val = parseFloat(val);
                if (!cl || isNaN(val) || typeof val !== 'number') {
                  throw 'Bad config';
                }
                waypoint = _.assign(Object.create(waypointPrototype), {
                  unit: unit,
                  val: val,
                  cl: cl,
                  persistent: persist
                });
                this.waypoints.push(waypoint);
              } catch (e) {
                console.warn('Bad inline configuration provided to waypoints in element ', this.el, ' at waypoint ', configParts[0]);
              }
            }.bind(this));
          }
        },

        waypointPrototype = {
          val: 50,
          unit: '%',
          cl: '',
          persistent: false
        };

    function scrollListener() {
      var viewportHeight = document.documentElement.clientHeight;
      _.forEachRight(waypointElementList, function (waypointElement, elementIndex) {
        var offset = waypointElement.el.getBoundingClientRect().top - viewportHeight,
            scroll = window.scrollY;
        if (offset < 0 && window.scrollY > 0) {
          _.forEachRight(waypointElement.waypoints, function (waypoint, waypointIndex) {
            var test = false;
            switch (waypoint.unit) {
              case '%':
                var percentage = -offset / waypointElement.el.offsetHeight * 100;
                test = percentage > waypoint.val;
                break;
              case 'em':
                var elementFontSize = parseFloat(getComputedStyle(waypointElement.el).fontSize);
                test = -offset > elementFontSize * waypoint.val;
                break;
              case 'rem':
                var documentFontSize = parseFloat(getComputedStyle(document.body).fontSize);
                test = -offset > documentFontSize * waypoint.val;
                break;
              case 'vh':
                test = -offset > viewportHeight / 100 * waypoint.val;
                break;
              default:  // px
                test = -offset > waypoint.val;
                break;
            }
            if (test) {
              waypointElement.el.classList.add(waypoint.cl);

              // no need to continue tracking persistent elements after activation
              if (waypoint.persistent) {
                waypointElement.waypoints.splice(waypointIndex, 1);
              }
            } else {
              waypointElement.el.classList.remove(waypoint.cl);
            }
          });
        } else {
          _.forEach(waypointElement.waypoints, function (waypoint) {
            waypointElement.el.classList.remove(waypoint.cl);
          });
        }

        // prune inactive waypoint elements
        if (waypointElement.waypoints.length === 0) {
          waypointElementList.splice(elementIndex, 1);
        }
      });
    }

    // public: add elements to scroll tracking
    function add(els, options) {
      options = options || {};
      els = toArray(els);
      _.forEach(els, function (el) {
        var waypointElement = _.assign(Object.create(waypointElementPrototype), { el: el, waypoints: [] }, options);
        waypointElementList.push(waypointElement);
        waypointElement.init();
      });
      return list();
    }

    // public: returns an array of all elements currently tracked by waypoints
    function list() {
      return waypointElementList;
    }

    // public: track default elements and add scroll listener
    function init() {
      _.forEach(document.querySelectorAll('[data-waypoints]'), add);
      // do initial pass
      scrollListener();
      // debounce function and listen
      window.addEventListener('scroll', _.throttle(scrollListener, 150));
    }

    return {
      init: init,
      list: list,
      add: add
    };
  }());


  /* =truncators
   * ===========
   *
   * truncate containers to number of lines, fixed height, and so on
   *
   * usage:
   * <div data-truncate-to="5 lines" data-truncate-text="Show More"></div>
   * <div data-truncate-to="100px"></div>
   * <div data-truncate-to="2em"></div>
   * <div data-truncate-to="3 items"></div>
   * etc.
   */

  var truncators = (function () {
    var buttonPrototype = document.createElement('button'),
        truncatorList = [],

        truncatorPrototype = {
          container: null,
          button: null,
          buttonText: "Show More",
          buttonClass: "truncator-expand",
          expandedButtonText: "Show Less",
          leeway: null,
          limit: null,
          limitUnit: null,
          isRepeatable: false,
          isTruncated: false,
          style: null,
          truncatedClass: 'is-truncated',

          init: function () {
            this.style = this.container.getAttribute('data-truncate-style') || null;
            this.buttonText = this.container.getAttribute('data-truncate-text') || this.buttonText;
            this.buttonClass = this.container.getAttribute('data-truncate-class') || this.buttonClass;

            var configString = this.container.getAttribute('data-truncate-to');

            // parse units
            if ((!this.limit || !this.limitUnit) && configString) {
              this.limit = parseInt((configString.match(/^\d+/) || [''])[0], 10);
              this.limitUnit = (configString.match(/[a-z]+$/i) || [''])[0].trim();
            }
            if (!this.limit || isNaN(this.limit) || !this.limitUnit) {
              console.error("Bad limit or unit config provided to following truncator:", this.container);
              return;
            }

            this.leeway = parseInt(this.container.getAttribute('data-truncate-leeway')) || 0;

            if (!this.button) {
              this.button = buttonPrototype.cloneNode(true);
              this.button.className = this.buttonClass;
            }

            this.truncate();
            setTimeout(this.truncate.bind(this), 500);  // catch some late-loading images

            this.button.addEventListener('click', function (ev) {
              if (this.isTruncated) {
                this.expand();
              } else {
                this.truncate();
              }
            }.bind(this));
          },

          truncate: function () {
            var normalizedLimit,
                normalizedLeeway,
                buttonSuffix = '';

            if (this.isTruncated) return;

            // if we're limiting by items, we can hide items instead of limiting pixel height
            if (this.limitUnit === 'item' || this.limitUnit === 'items') {
              var items = this.container.children,
                  hideCount = items.length - this.limit;

              // do no truncation if there aren't enough items
              if (hideCount <= 0) {
                return;
              }

              for (var i = this.limit; i < items.length; i++) {
                items[i].style.display = 'none';
              }

              if (hideCount > 0) {
                if (this.style === 'tags') {
                  this.buttonText = 'Show ' + hideCount + ' more tag' + (hideCount > 1 ? 's' : '');
                } else {
                  buttonSuffix = ' (' + hideCount + ' item' + (hideCount > 1 ? 's' : '') + ')';
                }
              }

            // if we're not limiting by items, we'll need to calculate a max-height
            } else {
              this.container.style.overflow = 'hidden';
              switch (this.limitUnit) {
                case '%':
                  normalizedLimit = this.container.clientHeight * this.limit / 100;
                  normalizedLeeway = this.container.clientHeight * this.leeway / 100;
                  break;

                case 'em':
                  // compute font size of element in px and multiply
                  let fontSize = parseFloat(getComputedStyle(this.container).fontSize);
                  normalizedLimit = fontSize * this.limit;
                  normalizedLeeway = fontSize * this.leeway;
                  break;

                case 'rem':
                  // compute font size of body in px and multiply
                  let remSize = parseFloat(getComputedStyle(document.body).fontSize);
                  normalizedLimit = remSize * this.limit;
                  normalizedLeeway = remSize * this.leeway;
                  break;

                case 'vh':
                  // take a percentage of viewport height
                  normalizedLimit = document.documentElement.clientHeight * this.limit / 100;
                  normalizedLeeway = document.documentElement.clientHeight * this.leeway / 100;
                  break;

                case 'vw':
                  // take a percentage of viewport width
                  normalizedLimit = document.documentElement.clientWidth * this.limit / 100;
                  normalizedLeeway = document.documentElement.clientWidth * this.leeway / 100;
                  break;

                case 'line':
                case 'lines':
                  // calculate pixel height of a line and multiply
                  (function (container, limit, leeway) {
                    var testEl = document.createElement('div'),
                        lineHeight;
                    testEl.textContent = 'A';
                    container.insertBefore(testEl, container.firstElementChild);
                    lineHeight = testEl.offsetHeight;
                    container.removeChild(testEl);
                    normalizedLimit = lineHeight * limit;
                    normalizedLeeway = lineHeight * leeway;
                  })(this.container, this.limit, this.leeway);
                  break;

                default:  // px
                  normalizedLimit = this.limit;
                  normalizedLeeway = this.leeway;
                  break;
              }

              if (normalizedLimit + normalizedLeeway >= this.container.clientHeight) return;
              this.container.style.maxHeight = normalizedLimit + 'px';
            }

            this.button.textContent = this.buttonText + buttonSuffix;
            if (this.style === 'tags') {
              this.container.appendChild(this.button);
            } else {
              this.container.parentNode.insertBefore(this.button, this.container.nextElementSibling);
            }

            this.isTruncated = true;
            this.container.classList.add(this.truncatedClass);
          },

          expand: function () {
            if (this.limitUnit === 'item' || this.limitUnit === 'items') {
              _.forEach(this.container.children, function (item) {
                item.style.display = '';
              });
            } else {
              this.container.style.overflow = '';
              this.container.style.maxHeight = '';
            }

            if (this.isRepeatable) {
              this.button.textContent = this.expandedButtonText;
            } else {
              this.button.parentNode.removeChild(this.button);
            }

            this.isTruncated = false;
            this.container.classList.remove(this.truncatedClass);
          }
        };

    buttonPrototype.setAttribute('type', 'button');

    function add(els, options) {
      els = toArray(els);
      options = options || {};
      _.forEach(els, function (el) {
        var truncator = _.assign(Object.create(truncatorPrototype), {container: el}, options);
        truncatorList.push(truncator);
        truncator.init();
      });
    }

    function init() {
      add(document.querySelectorAll('[data-truncate-to]'));
    }

    return {
      init: init,
      add: add
    };
  })();


  const forms = (function () {
    const exports = Object.create(null);

    // allow visual focus and selection indicators of input labels
    exports.focusLabels = function focusLabels() {
      const FOCUS_CLASS = 'is-focused';
      const SELECTED_CLASS = 'is-selected';
      const ENABLED_CLASS = 'can-enhance';
      const PRESENT_CONTENT_CLASS = 'has-content';
      const PAST_CONTENT_CLASS = 'did-have-content';

      toArray(document.getElementsByTagName('label')).forEach(function (label) {
        var input = document.getElementById(label.getAttribute('for')) ||
                    label.querySelector('input, textarea, select');
        var hasContainedContent = false;

        if (!input) return false;

        // let our css know we can use fancy labels
        if (isTextInput(input) && !input.multiple) {
          label.classList.add(ENABLED_CLASS);

          // initial state
          if (input.value) {
            label.classList.add(PRESENT_CONTENT_CLASS);
            hasContainedContent = true;
          }

          // changes
          input.addEventListener('input', function (ev) {
            if (input.value) {
              hasContainedContent = true;
              label.classList.remove(PAST_CONTENT_CLASS);
              label.classList.add(PRESENT_CONTENT_CLASS);
            } else {
              label.classList.remove(PRESENT_CONTENT_CLASS);
              if (hasContainedContent) {
                label.classList.add(PAST_CONTENT_CLASS);
              }
            }
          });
        }

        input.addEventListener('focus', function (ev) {
          label.classList.add(FOCUS_CLASS);
        });
        input.addEventListener('blur', function (ev) {
          label.classList.remove(FOCUS_CLASS);
        });

        // trigger 'change' event manually in order to populate highlight states on page load
        if ("createEvent" in document) {
          var ev = document.createEvent("HTMLEvents");
          ev.initEvent("change", false, true);
          input.dispatchEvent(ev);
        } else {
          input.fireEvent("onchange");
        }
      });
    };

    var isTextInput = (function () {
      var textInputTypes = ['color', 'date', 'email', 'number', 'password', 'search', 'tel', 'text', 'time', 'url'];
      return function isTextInput(el) {
        return textInputTypes.includes(el.getAttribute('type'));
      };
    })();


    /*
     * Allow certain text inputs to expand vertically to fit their contents
     */
    exports.autoGrowInputs = function autoGrowInputs() {
      toArray(document.getElementsByClassName('auto-grow')).forEach(function (input) {
        input.style.height = input.scrollHeight + 'px';
        input.addEventListener('input', function (ev) {
          input.style.height = '';
          input.style.height = input.scrollHeight + 'px';
        });
      });
    };


    /*
     * Enhanced multiselect interface
     */
    exports.enhanceMultiselects = function () {

      const inputs = document.querySelectorAll('select.modal-select');
      const openClass = 'is-active';
      const selectedClass = 'is-selected';
      const filteredClass = 'is-filtered';
      let resumeFocus = null;

      if (inputs.length === 0) return;

      // construct initial markup on page load and attach to document
      const container = document.createElement('div');
      container.className = 'modal-chooser';
      container.innerHTML = `
        <div class="modal-chooser__screen"></div>
        <div class="modal-chooser__container">
          <h3 class="modal-chooser__label">
            <span class="modal-chooser__label-text"></span>
            <small><span class="modal-chooser__count"></span> Selected</small>
          </h3>
          <input type="text" placeholder="Type to filter options" class="modal-chooser__search" />
          <ul class="modal-chooser__list">
          </ul>
          <button type="button" class="button button--fill modal-chooser__close">Done selecting</button>
        </div>
      `;
      document.body.appendChild(container);

      // get hooks into widget markup
      const closeButton = container.querySelector('.modal-chooser__close');
      const count = container.querySelector('.modal-chooser__count');
      const label = container.querySelector('.modal-chooser__label-text');
      const list = container.querySelector('.modal-chooser__list');
      const screen = container.querySelector('.modal-chooser__screen');
      const search = container.querySelector('.modal-chooser__search');

      // attach universal listeners
      closeButton.addEventListener('click', closeModal);
      screen.addEventListener('click', closeModal);
      document.body.addEventListener('keyup', function (ev) {
        if (ev.keyCode === 27) {  // esc
          closeModal();
        }
      });


      // do per-input setup
      _.forEach(inputs, function (input) {

        let selectedCount = 0;

        // set up inline markup
        const output = document.createElement('div');
        output.className = 'modal-select__output';
        input.parentNode.insertBefore(output, input.nextElementSibling);

        // set up closed state
        const openButton = document.createElement('button');
        openButton.setAttribute('type', 'button');
        openButton.className = 'modal-select__open button button--small';
        openButton.innerHTML = 'Select';
        if (input.multiple) openButton.innerHTML += ' <i class="fas fa-plus"></i>';
        output.appendChild(openButton);
        openButton.addEventListener('click', openModal);

        // collect all choices into an object and add artificial choices
        var options = toArray(input.querySelectorAll('option'))
          .map(option => {
            const li = document.createElement('li');
            li.className = 'modal-chooser__item';
            li.setAttribute('tabindex', '0');
            li.innerHTML = '<i class="fas fa-check-circle"></i> ' + option.textContent;
            if (option.selected) {
              li.classList.add(selectedClass);
              selectedCount++;
            }
            return {
              origin: option,
              li: li,
              searchText: option.textContent.replace(/\s+/g, '').toLowerCase(),
              output: null,
            };
          });

        // set up modal labeling text
        let labelText = input.multiple ? 'Choose items' : 'Choose an item';
        let inputLabel = input.id ? document.querySelector('label[for="' + input.id + '"]') : null;
        // fallback: search for wrapped label with associated input-label__text
        if (!inputLabel) {
          inputLabel = input.parentNode.querySelector('.input-label__text');
        }
        if (inputLabel) {
          labelText = inputLabel.textContent;
          inputLabel.addEventListener('click', function (ev) {
            ev.preventDefault();
            openModal();
          });
        }

        // attach events and initial state for actual selection
        _.forEach(options, option => {
          if (option.origin.selected) {
            addItem(option);
          }
          option.li.addEventListener('click', function (ev) {
            ev.preventDefault();
            toggleSelected(option);
          });
          option.li.addEventListener('keyup', function (ev) {
            // enter or spacebar
            if (ev.keyCode === 13 || ev.keyCode === 32) {
              ev.preventDefault();
              toggleSelected(option);
            }
          });
        });

        function toggleSelected(option) {
          option.origin.selected = !option.origin.selected;
          if (option.origin.selected) {
            option.li.classList.add(selectedClass);
            selectedCount++;
            addItem(option);
          } else {
            option.li.classList.remove(selectedClass);
            if (option.output) {
              option.output.parentNode.removeChild(option.output);
              option.output = null;
            }
            selectedCount--;
          }
          count.textContent = selectedCount.toString();
        }

        function deselectAll() {
          _.forEach(options, option => {
            if (option.origin.selected) {
              toggleSelected(option);
            }
          });
        }

        // populate the dialog, then open it
        function openModal() {
          // update modal contents
          label.textContent = labelText;
          count.textContent = selectedCount.toString();
          _.forEach(options, option => list.appendChild(option.li));

          // activate modal
          container.classList.add(openClass);

          // reset filtering and focus
          unfilterAll();
          search.addEventListener('input', filterItems);
          search.focus();

          // add focus target for closing
          resumeFocus = openButton;

          // prevent background scroll
          document.documentElement.style.overflow = 'hidden';
        }

        function addItem(option) {
          const result = document.createElement('button');
          result.className = 'button button--cancel button--small';
          result.setAttribute('type', 'button');
          result.innerHTML = option.origin.textContent + ' <i class="far fa-times-circle"></i>';
          output.insertBefore(result, openButton);

          option.output = result;

          function remove() {
            toggleSelected(option);
            result.removeEventListener('click', remove);
            result.parentNode.removeChild(result);
          }

          result.addEventListener('click', remove);
        }

        function filterItems() {
          _.forEach(options, option => {
            if (!search.value) {
              option.li.classList.remove(filteredClass);
            }
            const searchValue = search.value.replace(/\s+/g, '').toLowerCase();
            if (option.searchText.indexOf(searchValue) >= 0) {
              option.li.classList.remove(filteredClass);
            } else {
              option.li.classList.add(filteredClass);
            }
          });
        }

        function unfilterAll() {
          _.forEach(options, option => {
            option.li.classList.remove(filteredClass);
          });
        }

        // let our styles know it's okay to hide the original input
        input.classList.add('is-enhanced');
      });


      // close the dialog and empty it out
      function closeModal() {
        container.classList.remove(openClass);
        // removes these elements from the dom, but because we still have refs
        // to them, we can put the same ones back later
        list.innerHTML = '';
        label.textContent = '';
        count.textContent = '';
        search.value = '';

        // return to normal body scrolling behavior
        document.documentElement.style.overflow = '';

        // return keyboard focus to originating position
        if (resumeFocus) {
          resumeFocus.focus();
          resumeFocus = null;
        }
      }
    };


    return exports;
  })();


  /* =misc
   * =====
   *
   * miscellaneous enhancements that aren't large enough to justify a module
   */

  var misc = (function () {

    var exports = Object.create(null);


    // mobile menu toggle
    exports.addMobileMenuToggle = function addMobileMenuToggle() {
      var inactiveClass = 'mobile-menu-was-open';
      toggles.add(document.getElementsByClassName('page-header__toggle'), {
        targets: toArray(document.querySelectorAll('body, .page-header__content')),
        activeClass: 'mobile-menu-is-open',
        deactivateOnEsc: true,
        onActivate: function (toggle) {
          // add an inactive class only after the menu has been opened and then closed
          if (!toggle.origin.classList.contains(toggle.activeClass)) {
            _.forEach([toggle.origin].concat(toggle.targets), function (el) {
              el.classList.add(inactiveClass);
            });
          } else {
            _.forEach([toggle.origin].concat(toggle.targets), function (el) {
              el.classList.remove(inactiveClass);
            });
          }
        }
      });
    };


    // automatically add arrow indicators to standard accordions
    exports.addAccordionIndicators = function addAccordionIndicators() {
      var indicator = document.createElement('span');
      indicator.className = 'fas fa-arrow-right';

      _.forEach(document.querySelectorAll('.accordion__label'), function (label) {
        var childDivs = label.querySelectorAll('div');
        (childDivs.length > 0 ? childDivs[childDivs.length - 1] : label).appendChild(indicator.cloneNode(true));
      });
    };


    // vertically resize chat embed to visually match side-by-side video
    // assumes only one pair per page
    exports.matchChatHeight = function matchChatHeight() {
      const video = document.querySelector('.sbs-chat__video iframe');
      const chat = document.querySelector('.sbs-chat__chat iframe');

      if (!chat || !video) return;

      matchHeight();
      window.addEventListener('resize', matchHeight);

      function matchHeight() {
        const videoY = video.getBoundingClientRect().y;
        const chatY = chat.getBoundingClientRect().y;
        // only match height if the two are side by side
        if (Math.abs(videoY - chatY) <= 1) {
          chat.style.height = video.offsetHeight + 'px';
        } else {
          chat.style.height = '';
        }
      }
    };


    // make sure the page title makes enough room for a page's actions
    exports.padPageTitle = function padPageTitle() {
      let wasLastPositioned = false;  // used to only update dom properties on
                                      // breakpoint changeover
      const pageTitle = document.querySelector('.page-title');
      const pageActions = document.querySelector('.page-actions');

      if (!pageTitle) return;

      if (!pageActions) {
        // if no page actions exist, we can delete the css' assumed padding
        pageTitle.style.paddingRight = '0';
        return;
      }

      function doPad() {
        if (window.getComputedStyle(pageActions).position === 'absolute') {
          if (!wasLastPositioned) {
            pageTitle.style.paddingRight = pageActions.offsetWidth + 'px';
            wasLastPositioned = true;
          }
        } else {
          if (wasLastPositioned) {
            pageTitle.style.paddingRight = '0';
            wasLastPositioned = false;
          }
        }
      }

      doPad();
      window.addEventListener('resize', doPad);
    };


    // ticking countdowns
    // usage: <el data-countdown-to="timestamp"></el>, where 'timestamp' is in
    // milliseconds since epoch
    exports.countdowns = function countdowns() {
      const countdowns = [];

      _.forEach(document.querySelectorAll('[data-countdown-to]'), function (el) {
        const target = new Date(parseInt(el.getAttribute('data-countdown-to'), 10));
        countdowns.push({
          el: el,
          target: target,
        });
      });

      const interval = setInterval(incrementCountdowns, 1000);

      function incrementCountdowns() {
        const now = new Date();
        for (let countdown of countdowns) {
          let diff = Math.floor((countdown.target - now) / 1000);
          if (diff <= 0) {
            countdown.el.textContent = '00:00:00';
            countdowns.splice(countdowns.indexOf(countdown), 1);
            continue;
          }

          const seconds = Math.floor(diff % 60);
          diff /= 60;
          const minutes = Math.floor(diff % 60);
          diff /= 60;
          const hours = Math.floor(diff % 24);
          diff /= 24;
          const days = Math.floor(diff % 7);
          diff /= 7;
          const weeks = Math.floor(diff);

          if (weeks > 0) {
            countdown.el.textContent = weeks + (weeks === 1 ? ' week, ' : ' weeks, ') +
                days + (days === 1 ? ' day, and ' : ' days, and ') +
                hours + (hours === 1 ? ' hour' : ' hours');
          } else if (days > 0) {
            countdown.el.textContent = days + (days === 1 ? ' day, ' : ' days, ') +
                hours + (hours === 1 ? ' hour, and ' : ' hours, and ') +
                minutes + (minutes === 1 ? ' minute' : ' minutes');
          } else {
            countdown.el.textContent = (days > 0 ? `${days}:` : '') +
                hours.toString().padStart(2, '0') + ':' +
                minutes.toString().padStart(2, '0') + ':' + 
                seconds.toString().padStart(2, '0');
          }
        }
      }
    };


    // automatically toggle open nav section housing the current nav item, and add unread indicators
    exports.openCurrentNavGroup = function openCurrentNavGroup() {
      _.forEach(toggles.find('.section-nav__group-toggle'), function (toggle) {
        const list = toggle.origin.parentNode.nextElementSibling;
        const unread = list.querySelector('.section-nav__unread');
        if (unread) {
          const unreadClone = unread.cloneNode(true);
          unreadClone.classList.add('section-nav__unread--group');
          toggle.origin.appendChild(unreadClone);
        }
        if (list.querySelector('.section-nav__item--current')) {
          toggle.activate(true);
        }
      });
    };


    // wrap user-entered tables in order to provide a better overflow experience in css
    exports.wrapUserTables = function wrapUserTables() {
      const wrapperProto = document.createElement('div');
      wrapperProto.className = 'table-wrapper';
      wrapperProto.innerHTML = '<div class="table-wrapper__scroller"></div>';

      _.forEach(document.querySelectorAll('.user-content table'), function (table) {
        const wrapper = wrapperProto.cloneNode(true);
        const inner = wrapper.firstElementChild;
        table.parentNode.insertBefore(wrapper, table);
        inner.appendChild(table);
      });
    };


    // intercept clicks on interest buttons to reload item content via ajax
    exports.activateInterestButtons = function activateInterestButtons() {
      document.addEventListener('click', function (ev) {
        for (var target = ev.target; target && target !== this; target = target.parentNode) {
          // is this click for an interest button?
          if (target.matches('a.interest-button') && target.href) {
            // find the item to which this button belongs
            for (var n = target.parentNode; n && n !== document; n = n.parentNode) {
              if (n.matches('.block-list__item')) {
                // load the updated markup for the item, and replace it in the DOM
                xhr("GET", target.href, function (response) {
                  var tmp = document.implementation.createHTMLDocument("");
                  tmp.body.innerHTML = response;
                  var newItem = tmp.body.firstElementChild;

                  n.replaceWith(newItem);
                  RDJS.dateTime.install(newItem);
                  RDJS.toggles.addChildren(newItem);
                });
                ev.preventDefault();
                break;
              }
            }
            break;
          }
        }
      }, true);
    };


    exports.markUserFloats = function markUserFloats() {
      _.forEach(document.querySelectorAll('.user-content'), function (container) {
        _.forEach(container.querySelectorAll('*'), function (el) {
          const float = el.style.float;
          const align = (el.getAttribute('align') || '').toLowerCase();

          if (float === 'left' || align === 'left') {
            el.classList.add('float-left');
          } else if (float === 'right' || align === 'right') {
            el.classList.add('float-right');
          }

          if (align === 'left' || align === 'right') {
            el.setAttribute('align', '');
          }
        });
      });
    };


    return exports;

  }());


  /* =initialize modules
   * ===================
   */

  var init = function () {

    // large modules
    toggles.init();
    tabs.init();
    sharedHeights.init();
    waypoints.init();
    truncators.init();

    // form-specific enhancements
    forms.focusLabels();
    forms.autoGrowInputs();
    forms.enhanceMultiselects();

    // misc modules
    misc.addMobileMenuToggle();
    misc.openCurrentNavGroup();
    misc.padPageTitle();
    misc.addAccordionIndicators();
    misc.matchChatHeight();
    misc.countdowns();
    misc.wrapUserTables();
    misc.activateInterestButtons();
    misc.markUserFloats();

    document.documentElement.classList.remove('no-js');
    document.documentElement.classList.add('js');
  };


  /* =public
   * =======
   */

  return {
    xhr: xhr,
    toggles: toggles,
    tabs: tabs,
    sharedHeights: sharedHeights,
    waypoints: waypoints,
    init: init
  };

}(window, document));

RDJS.init();

